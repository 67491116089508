var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "infotDetai" }, [
      _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.dataObj.title))]),
      _c("p", { staticClass: "content" }, [
        _c(
          "span",
          {
            staticClass: "time",
            staticStyle: { "margin-top": "5px", display: "block" }
          },
          [_vm._v(_vm._s(_vm.dataObj.createTime || _vm.dataObj.updateTime))]
        )
      ]),
      _c("div", {
        staticClass: "edit",
        domProps: { innerHTML: _vm._s(_vm.dataObj.content) }
      }),
      _c(
        "div",
        { staticClass: "edirct", staticStyle: { "margin-top": "40px" } },
        [
          _vm.file.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "16px",
                    "font-weight": "bold",
                    "margin-bottom": "10px"
                  }
                },
                [_vm._v("附件：")]
              )
            : _vm._e(),
          _vm._l(_vm.file, function(i, index) {
            return _c(
              "a",
              { key: index, staticClass: "fileUrl", attrs: { href: i.url } },
              [
                _c(
                  "p",
                  {
                    staticStyle: { "margin-bottom": "10px", color: "#004E98" }
                  },
                  [_vm._v(_vm._s(i.name))]
                )
              ]
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }